import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";
import ContractForm from '../components/contact-form';

// markup
const ContactPage = () => {
  return (
    <Layout pageClass="contact-page">
        <Helmet>
            <title>MatteCurve - Contact Us | Top Web and Mobile App Development Company In India</title>
            <meta name="description" content="MatteCurve is one of the leading javascript development company in India providing Custom Software, Web and Mobile Application Development services like node.js, meteor.js, react.js and many more services." />
            <meta name="keywords" content="Web Development, Mobile App Development, React, Node.js, Angular.js, Remote Developers" />
        </Helmet>
        <Header></Header>
        <main>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="animate__animated animate__fadeInUp">We'd Love to Talk</h1>
                        <br/>
                        <div className="animate__animated animate__fadeInUp">
                            <p>
                            As a consultancy, MatteCurve provides technical expertise and project leadership. We’ll help to build your strategy, enact best practices to ensure a maintainable and sustainable product, and help your team level up along the way.
                            </p>
                            <p>
                            We'd love to hear from you even if you are not sure where to begin. That's fine!
                            </p>
                            <p>
                            Contact us today or schedule a 30-minute consultation call to talk through your idea, project, or goals.
                            </p>
                            <p>
                                <u>
                                    <a style={{
                                    color: '#04009A'
                                }} href='https://calendly.com/onkarjanwa/product-discussion'>Schedule a call now</a>
                                </u>
                            </p>
                        </div>
                        <br/>
                        <div className="animate__animated animate__fadeInUp animate__faster">
                            <span>General Enquiries</span>
                            <h6>
                                <a href="mailto:hello@mattecurve.com">hello@mattecurve.com</a>
                            </h6>
                        </div>
                        <br/>
                        {/* <div className="animate__animated animate__fadeInUp animate__fast">
                            <span>Call us</span>
                            <h4><a href="tel:+91-7976475525">+91-7976475525</a></h4>
                        </div>
                        <br/> */}
                        <div className="animate__animated animate__fadeInUp animate__fast">
                            <span>Office</span>
                            <h6>Jaipur, India</h6>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-5 mt-md-0">
                        <div className="contact-form-column">
                            <ContractForm />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer></Footer>
    </Layout>
  )
}

export default ContactPage;
